import axios from "./axios";

export default class ProductService {
	async getCategories() {
		const response = await axios.get(`api/category/product-categories`);

		return response;
	}
	async createCategory(categoryData) {
		const response = await axios.post(
			`api/category/category?categoryName=${categoryData.categoryName}`
		);

		return response;
	}
	async updateCategory(categoryData) {
		const response = await axios.patch(
			`api/category/category?prodCategoryId=${categoryData.prodCategoryId}&&categoryName=${categoryData.categoryName}`
		);

		return response;
	}
	async deleteCategory(id) {
		const response = await axios.delete(
			`api/category/category?productCategoryId=${id}`
		);

		return response;
	}
	async getProducts() {
		const response = await axios.get(`api/product/products`);

		return response;
	}
	async createProduct(productData) {
		const response = await axios.post(`api/product/product`, productData);

		return response;
	}
	async bulkUpdatePrices(multiplier, productData) {
		const response = await axios.patch(
			`api/product/update-all-product-pricing?newMultiplierPrice=${multiplier}`,
			productData
		);

		return response;
	}
	async updateProduct(productData) {
		const response = await axios.patch(`api/product/product`, productData);

		return response;
	}
	async deleteProduct(id) {
		const response = await axios.delete(`api/product/product?productId=${id}`);

		return response;
	}
	async updateProductStatus(id) {
		const response = await axios.patch(
			`api/product/product-status?productId=${id}`
		);

		return response;
	}
}
