import queryString from "query-string";
import axios from "./axios";

export default class MimicService {
	async getWhatsappLogs(queryParams) {
		const response = await axios.get(
			`api/mimic/whatsapp/history?${queryString.stringify(queryParams)}`
		);
		return response;
	}
	async getEalierWhatsappLogs(queryParams) {
		const response = await axios.get(
			`api/mimic/whatsapp/history/earlier-messages?${queryString.stringify(
				queryParams
			)}`
		);
		return response;
	}
	async toggleStoreChat(storeData) {
		const response = await axios.patch(
			`api/mimic/substore/chat-toggle?subStoreId=${storeData.subStoreId}`
		);
		return response;
	}
	async toggleCustomerChat(storeData) {
		const response = await axios.patch(
			`api/mimic/customer/chat-toggle?userPhoneNumber=${encodeURIComponent(
				storeData.userPhoneNumber
			)}`
		);
		return response;
	}
	async sendMessage(messageData) {
		const response = await axios.post(`api/mimic/whatsapp/send-message`, {
			storeId: messageData.storeId,
			subStoreId: messageData.subStoreId,
			message: messageData.message,
			recipientPhoneNumber: messageData.recipientPhoneNumber,
		});

		return response;
	}
}
