import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	products: [],
	totalProducts: 0,
	categories: [],
};

const ProductReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_PRODUCTS.REQUEST:
		case types.CREATE_PRODUCT.REQUEST:
		case types.UPDATE_PRODUCT.REQUEST:
		case types.GET_CATEGORIES.REQUEST:
		case types.CREATE_CATEGORY.REQUEST:
		case types.UPDATE_CATEGORY.REQUEST:
		case types.DELETE_CATEGORY.REQUEST:
		case types.DELETE_PRODUCT.REQUEST:
		case types.BULK_UPDATE_PRICES.REQUEST:
		case types.UPDATE_PRODUCT_STATUS.REQUEST:
			return { ...state, loading: true };
		case types.GET_PRODUCTS.SUCCESS:
			return {
				...state,
				loading: false,
				products: payload.products,
				totalProducts: payload.totalProduct,
			};
		case types.GET_CATEGORIES.SUCCESS:
			return { ...state, loading: false, categories: [...payload] };
		case types.CREATE_PRODUCT.SUCCESS:
		case types.UPDATE_PRODUCT.SUCCESS:
		case types.CREATE_CATEGORY.SUCCESS:
		case types.UPDATE_CATEGORY.SUCCESS:
		case types.DELETE_CATEGORY.SUCCESS:
		case types.DELETE_PRODUCT.SUCCESS:
		case types.BULK_UPDATE_PRICES.SUCCESS:
		case types.UPDATE_PRODUCT_STATUS.SUCCESS:
			return { ...state, loading: false };
		case types.GET_PRODUCTS.FAILURE:
		case types.CREATE_PRODUCT.FAILURE:
		case types.UPDATE_PRODUCT.FAILURE:
		case types.GET_CATEGORIES.FAILURE:
		case types.CREATE_CATEGORY.FAILURE:
		case types.UPDATE_CATEGORY.FAILURE:
		case types.DELETE_CATEGORY.FAILURE:
		case types.DELETE_PRODUCT.FAILURE:
		case types.UPDATE_PRODUCT_STATUS.FAILURE:
		case types.BULK_UPDATE_PRICES.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default ProductReducer;
