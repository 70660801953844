import React, { useContext, useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import { mainFunctions } from "../providers/MainProvider";
import { bulkUpdatePricesAsync, getProductsAsync } from "../redux/actions";
import { showToast } from "../utils";
import { MultiSelect } from "react-multi-select-component";

const BulkUpdatePricing = () => {
	const { setShowModal } = useContext(mainFunctions);
	const dispatch = useDispatch();
	const { loading, products } = useSelector((state) => state.product);

	const [selected, setSelected] = useState([]);
	const [options, setOptions] = useState([]);
	const [data, setData] = useState({
		products: [],
		factor: "",
	});
	useEffect(() => {
		setOptions(() => products.map((x) => ({ label: x.name, value: x.id })));
	}, [products]);

	useEffect(() => {
		setData({ ...data, products: selected.map((x) => x.value) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	const customItemRenderer = ({ checked, option, onClick }) => (
		<div
			className={`w-full flex items-center gap-2 p-2 rounded cursor-pointer 
      ${
				option.disabled ? "bg-gray-200 cursor-not-allowed" : "hover:bg-blue-100"
			}`}
			onClick={option.disabled ? undefined : onClick}
		>
			<span
				className={`${option.disabled ? "text-gray-500" : "text-gray-900"}`}
			>
				{option.label}
			</span>
		</div>
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { products, factor } = data;
		if (!factor) {
			return showToast("Fill out all the fields", "error");
		}
		const payload = {
			products: products,
			factor: factor,
		};
		dispatch(
			bulkUpdatePricesAsync(payload, async (_) => {
				dispatch(getProductsAsync());
				setShowModal(false);
			})
		);
	};
	return (
		<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">Bulk Update Prices</div>
			<p className="subtitle text-center"></p>
			<div className="auth_form_container">
				<div className="fieldset">
					<label className="label mb-0">Price Multiplier</label>
					<div className="input_box">
						<CurrencyFormat
							value={data.factor}
							thousandSeparator={false}
							onValueChange={(values) => {
								const { value } = values;
								setData({
									...data,
									factor: value,
								});
							}}
						/>
					</div>
				</div>
				<div className="fieldset">
					<label className="label mb-0">Products To Exclude</label>
					<MultiSelect
						options={options}
						value={selected}
						onChange={setSelected}
						labelledBy="Select"
						ItemRenderer={customItemRenderer}
					/>
				</div>

				<div
					className="btn_ btn_orange"
					onClick={!loading ? handleSubmit : () => {}}
				>
					{loading ? (
						<div className="spinner-border" role="status"></div>
					) : (
						"Proceed"
					)}
				</div>
			</div>
		</div>
	);
};

export default BulkUpdatePricing;
