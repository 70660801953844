import { mimicService } from "../../services";
import * as types from "../types";
import { showErrorMessage } from "./auth.actions";

export const getWhatsappLogsAsync = (
	queryParams,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.GET_WHATSAPP_LOGS.REQUEST });
		try {
			const responseData = await mimicService.getWhatsappLogs(queryParams);
			dispatch({
				type: types.GET_WHATSAPP_LOGS.SUCCESS,
				payload: responseData.data.data,
			});
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.GET_WHATSAPP_LOGS.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const getEalierWhatsappLogsAsync = (
	queryParams,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		try {
			const responseData = await mimicService.getEalierWhatsappLogs(
				queryParams
			);
			successCallback?.(responseData.data.data);
		} catch (err) {
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};

export const sendMessageAsync = (
	messageData,
	successCallback,
	errorCallback
) => {
	return async (dispatch) => {
		dispatch({ type: types.SEND_MESSAGE.REQUEST });
		try {
			await mimicService.sendMessage(messageData);
			dispatch({ type: types.SEND_MESSAGE.SUCCESS });
			successCallback?.();
		} catch (err) {
			dispatch({ type: types.SEND_MESSAGE.FAILURE, payload: err });
			errorCallback?.();
			showErrorMessage(err);
		}
	};
};
