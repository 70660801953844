import React, { useContext, useState, useEffect } from "react";
import { mainFunctions } from "../../providers/MainProvider";

import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import SummaryBox from "../../components/SummaryBox";
import EditProduct from "../../modals/EditProduct";
import RemoveProduct from "../../modals/RemoveProduct";
import AddProduct from "../../modals/AddProduct";
import { useGuard } from "../../hooks/useGuard";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getProductsAsync } from "../../redux/actions";
import { formatToCurrency, getDateTimeFormatUK } from "../../utils";
import Tables from "../../components/Tables";
import AnalyticsWrapper from "../../components/AnalyticsWrapper";
import BulkUpdatePricing from "../../modals/BulkUpdatePricing";

export default function Products() {
	useGuard();
	const dispatch = useDispatch();
	const { products, totalProducts } = useSelector((state) => state.product);
	useEffect(() => {
		dispatch(getProductsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { setShowModal, setModalData } = useContext(mainFunctions);

	const tableColumns = [
		{
			title: "Product ID",
			dataIndex: "id",
		},
		{
			title: "Product Name",
			dataIndex: "name",
		},
		{
			title: "Unit Price",
			dataIndex: "unitPrice",
		},
		{
			title: "No of Purchases",
			dataIndex: "purchases",
			search: "false",
		},
		{
			title: "Date Uploaded",
			dataIndex: "date",
			search: "false",
		},
		{
			title: "",
			dataIndex: "option",
			search: "false",
		},
	];

	const [filteredTableData, setFilteredTableData] = useState(products);

	const [visibilities, setVisibilities] = React.useState(() =>
		filteredTableData.map((x) => false)
	);

	const handleClick = (index) => {
		const newVisibilities = [...visibilities];
		newVisibilities.map((thisVisibility, ind) => {
			index !== ind
				? (newVisibilities[ind] = false)
				: (newVisibilities[index] = !newVisibilities[index]);
			return 0;
		});
		setVisibilities(newVisibilities);
	};

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row, index) => {
					return {
						id: row.id,
						name: (
							<div className="flex items-center gap-2">
								{row.coverImage && (
									<span>
										<img
											src={row.coverImage}
											style={{
												width: "32px",
												height: "32px",
												objectFit: "cover",
											}}
											alt="img"
										/>
									</span>
								)}
								<span>{row.name}</span>
							</div>
						),
						unitPrice: <div>₦{formatToCurrency(row.unitPrice, 2)}</div>,
						purchases: row.totalNumberOfPurchase,
						date: <div>{getDateTimeFormatUK(row.dateCreated)}</div>,
						option: (
							<div className="">
								<div className="position-relative">
									<div
										className="d-flex items-center"
										style={{ cursor: "pointer" }}
									>
										<BsThreeDots onClick={() => handleClick(index)} size={24} />
									</div>
									{visibilities[index] ? (
										<div
											className="position-absolute border border-muted px-3 w-32 bg-white"
											style={{
												right: "0",
												top: "100%",
												zIndex: "2",
												width: "150px",
											}}
										>
											<div
												onClick={async () => {
													setModalData(<EditProduct product={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 border-bottom border-muted status-success hover:text-blue-dark text-small"
											>
												Edit Product
											</div>
											<div
												onClick={() => {
													setModalData(<RemoveProduct data={row} />);
													setShowModal(true);
												}}
												style={{ cursor: "pointer" }}
												className="d-flex text-left py-3 status-failed hover:text-blue-dark text-small"
											>
												Remove Product
											</div>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						),
					};
			  })
			: [];

	useEffect(() => {
		setFilteredTableData(products);
		setVisibilities(() => products.map((x) => false));
	}, [products]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Products" />
				<div className="mainbar-container">
					<div
						className="btn_ btn_green mb-3 top_btn"
						onClick={async () => {
							await setModalData(<AddProduct />);
							await setShowModal(true);
						}}
					>
						ADD PRODUCT
					</div>
					<div
						className="btn_ !text-[#f86010] !border-[1px] border-[#f86010] !border-solid !bg-dark-gray1 mb-3 top_btn"
						onClick={async () => {
							await setModalData(<BulkUpdatePricing />);
							await setShowModal(true);
						}}
					>
						Bulk update pricing
					</div>
					<AnalyticsWrapper>
						<SummaryBox
							title={"Total Products"}
							value={totalProducts}
							comma={true}
						/>
					</AnalyticsWrapper>
					<div className="col-sm-12">
						<Tables
							title="All Products"
							dataSource={dataSource}
							columns={tableColumns}
							handleSearch={true}
							showPagination={true}
							showPageSize={true}
							setFilteredTableData={setFilteredTableData}
							source={products}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
